import React, { useEffect } from "react";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BarChartIcon from "@mui/icons-material/BarChart";
import PersonIcon from "@mui/icons-material/Person";
import Cloud from "@mui/icons-material/Cloud";
import CloudOff from "@mui/icons-material/CloudOff";
import { Stack, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { getSession } from "../helpers/auth.helper";

export function NavigationBar() {
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const navigate = useNavigate();
	const [sessionId, setSessionId] = React.useState(false);
	useEffect(() => {
		setSessionId(getSession());
	}, []);

	return (
		<Stack
			direction="row"
			sx={{
				position: "relative",
				zIndex: 99999999999,
				display: "flex",
				justifyContent: "space-between",
				paddingTop: "0px",
				paddingBottom: "0px",
				maxHeight: "40px",
				minHeight: "40px",
				backgroundColor: "rgba(255, 255, 255, 0.06)",
			}}>
			<Button
				disabled={!sessionId}
				sx={{
					color:
						window.location.pathname === "/" ||
						window.location.pathname === "/start" ||
						window.location.pathname === "/game"
							? "white"
							: "primary.main",
				}}
				onClick={() => navigate("/")}
				fullWidth>
				<SportsEsportsIcon fontSize="large" /> {isPortrait ? "" : "Game"}
			</Button>
			<Button
				disabled={!sessionId}
				sx={{ color: window.location.pathname === "/decks" ? "white" : "primary.main" }}
				fullWidth
				onClick={() => navigate("/decks")}>
				<LibraryBooksIcon fontSize="large" />
				{isPortrait ? "" : "Decks"}
			</Button>
			<Button
				disabled={!sessionId}
				sx={{ color: window.location.pathname === "/players" ? "white" : "primary.main" }}
				fullWidth
				onClick={() => navigate("/players")}>
				<PersonIcon fontSize="large" />
				{isPortrait ? "" : "Players"}
			</Button>
			<Button
				disabled={!sessionId}
				sx={{ color: window.location.pathname === "/stats" ? "white" : "primary.main" }}
				fullWidth
				onClick={() => navigate("/stats")}>
				<BarChartIcon fontSize="large" />
				{isPortrait ? "" : "Stats"}
			</Button>
			<Button
				sx={{ color: window.location.pathname === "/login" ? "white" : "primary.main" }}
				fullWidth
				onClick={() => navigate("/login")}>
				{sessionId ? <Cloud fontSize="large" /> : <CloudOff fontSize="large" />}
				{isPortrait ? "" : "Connection"}
			</Button>
		</Stack>
	);
}
