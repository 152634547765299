import React from "react";
import { Stack, Slider } from "@mui/material";

const marks = [
	{
		value: 3,
		label: "All",
	},
	{
		value: 0,
		label: "10",
	},
	{
		value: 1,
		label: "25",
	},
	{
		value: 2,
		label: "50",
	},
];

export default function HistorySlider(props) {
	const [historyLimit, setHistoryLimit] = React.useState(3);

	const handleCommit = (event, newHistoryLimit) => {
		if (newHistoryLimit !== null && newHistoryLimit !== historyLimit) {
			setHistoryLimit(newHistoryLimit);
			marks.forEach((marker) => {
				if (newHistoryLimit === marker["value"]) {
					props.setHistoryLimit(marker["label"]);
				}
			});
		}
	};

	return (
		<Stack sx={{ml:2, mr:2}}>
			<Slider
				value={historyLimit}
				onChange={handleCommit}
				defaultValue={0}
				step={null}
				marks={marks}
				min={0}
				max={3}
			/>
		</Stack>
	);
}
