import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { Container, FormGroup, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const CreatePlayer = () => {
	const [playerName, setPlayerName] = useState("");
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(false);
	}, []);

	const submitChanges = (event) => {
		event.preventDefault();
		setLoading(true);
		const reqBody = {
			playerName: playerName,
		};
		axios
			.post("/api/players/add", reqBody)
			.then(() => {
				navigate("/players");
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const changeValue = (e) => {
		setPlayerName(e.target.value);
	};

	return (
		<Container
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}>
			<form onSubmit={submitChanges}>
				<TextField
					autoComplete="off"
					label="Player Name"
					variant="outlined"
					name="playerName"
					onChange={changeValue}
					value={playerName}
				/>
				<Button
					disabled={loading}
					variant="contained"
					type="submit"
					color="primary"
					className="mt-3">
					{!loading ? "Create player" : <CircularProgress size="1.5em" />}
				</Button>
			</form>
		</Container>
	);
};

export default CreatePlayer;
