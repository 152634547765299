import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { PlayerLifeColumn } from "./PlayerLifeColumn.comp";
import { Stack } from "@mui/material";
import { PlayerMidsection } from "./PlayerMidsection.comp";

export function PlayerLifeBox({ adjustValue, playerId, deckName, playerState, toggleCommand, playerName, starter }) {
	const [image, setImage] = useState("solring.jpg");

	useEffect(() => {
		if (deckName && image.includes("solring.jpg")) {
			getBackground();
		}
	}, [deckName, image]);

	function getBackground() {
		const deckNameUri = encodeURIComponent(deckName);
		const mainUrl = "https://api.scryfall.com/cards/named?fuzzy=";
		const targetUrl = mainUrl + deckNameUri;
		axios
			.get(targetUrl)
			.then((response) => {
				try {
					setImage(response.data["image_uris"]["art_crop"]);
				} catch (err) {
					try {
						setImage(response.data["card_faces"][0]["image_uris"]["art_crop"]);
					} catch {}
				}
			})
			.catch(() => {});
	}

	return (
		<motion.div
			key="player-life-box"
			initial={{ x: -50, opacity: 0 }}
			animate={{ x: 0, zIndex: 1, opacity: 1 }}
			exit={{ x: 50, zIndex: 0, opacity: 0 }}
			style={{
				flexGrow: "1",
				display: "flex",
				backgroundSize: "cover",
				backgroundImage: `url('${image || ""}')`,
				backgroundPosition: "center",
				borderTop: "solid gray 1px",
			}}>
			{/* not in love with this approach but it seems the easiest way to get the background blurring to work */}
			<Stack
				direction="row"
				sx={{
					backdropFilter: "blur(5px) grayscale(0.7) contrast(01)",
					boxShadow: "rgb(0, 0, 0) 0px 0px 300px 50px inset",
					flexGrow: "1",
				}}>
				<PlayerLifeColumn
					adjustValue={adjustValue}
					playerId={playerId}
					value={1}
				/>
				<PlayerMidsection
					deckName={deckName}
					playerName={playerName}
					playerState={playerState}
					adjustValue={adjustValue}
					playerId={playerId}
					toggleCommand={toggleCommand}
					starter={starter}
				/>
				<PlayerLifeColumn
					adjustValue={adjustValue}
					playerId={playerId}
					value={5}
				/>
			</Stack>
		</motion.div>
	);
}
