import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Button, TextField, Typography, CircularProgress, Stack } from "@mui/material";
import { loginUser, logOutUser, getSession } from "../helpers/auth.helper";
import { motion } from "framer-motion";
const forge = require("node-forge");

export function LoginPage() {
	const [uName, setUName] = useState("");
	const [uPass, setUPass] = useState("");
	const [uPassVerify, setUPassVerify] = useState("");
	const [loading, setLoading] = useState(true);
	const [showLogin, setShowLogin] = useState(true);
	const [sessionId, setSessionId] = useState(false);

	useEffect(() => {
		setSessionId(getSession());
		setLoading(false);
	}, []);

	const checkInputs = (createBool) => {
		let inputChecked = true;
		if (uName.length < 3) {
			inputChecked = false;
		}
		if (uPass.length < 3) {
			inputChecked = false;
		}
		if (createBool && uPass !== uPassVerify) {
			inputChecked = false;
		}
		return inputChecked;
	};

	const toggleLoginScreen = () => {
		setShowLogin(!showLogin);
	};

	const LoginSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		const validInputs = checkInputs(false);
		if (validInputs) {
			let uNameHashFunc = forge.md.sha256.create();
			uNameHashFunc.update(uName);
			let uNameHash = uNameHashFunc.digest().toHex();
			let uPassHashFunc = forge.md.sha256.create();
			uPassHashFunc.update(uPass);
			let uPassHash = uPassHashFunc.digest().toHex();

			clearInputs();
			axios
				.post("/api/sessions/retrieve", {
					userhash: uNameHash,
					passhash: uPassHash,
				})
				.then((response) => {
					loginUser(response.data);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else {
			setLoading(false);
			clearInputs();
		}
	};

	const clearInputs = () => {
		setUName("");
		setUPass("");
		setUPassVerify("");
	};

	const CreateSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		const validInputs = checkInputs(true);
		if (validInputs) {
			let uNameHashFunc = forge.md.sha256.create();
			uNameHashFunc.update(uName);
			let uNameHash = uNameHashFunc.digest().toHex();
			let uPassHashFunc = forge.md.sha256.create();
			uPassHashFunc.update(uPass);
			let uPassHash = uPassHashFunc.digest().toHex();
			clearInputs();
			axios
				.post(
					"/api/sessions/create",
					{},
					{
						auth: {
							username: uNameHash,
							password: uPassHash,
						},
					}
				)
				.then((response) => {
					loginUser(response.data);
				})
				.catch((error) => {
					setLoading(false);
				});
		} else if (uPass !== uPassVerify) {
			setLoading(false);
			clearInputs();
		} else {
			setLoading(false);
			clearInputs();
		}
		clearInputs();
	};

	const changeValue = (e) => {
		const { name, value } = e.target;
		if (name === "uName") setUName(value);
		else if (name === "uPass") setUPass(value);
		else if (name === "uPassVerify") setUPassVerify(value);
	};

	if (sessionId) {
		return (
			<Container
				initial={{ y: 50, zIndex: 0, opacity: 0 }}
				animate={{ y: 0, zIndex: 1, opacity: 1 }}
				exit={{ y: 50, zIndex: 0, opacity: 0 }}
				component={motion.div}>
				<Typography
					variant="h3"
					component="div"
					gutterBottom
					style={{ marginTop: "1em" }}>
					Connected
				</Typography>
				<Typography
					variant="h5"
					component="div"
					gutterBottom
					style={{ marginTop: "-1em" }}>
					to group <span style={{ fontStyle: "italic" }}>{sessionId?.slice(59)}</span>
				</Typography>
				<Button
					disabled={loading}
					variant="contained"
					color="primary"
					style={{ float: "right" }}
					className="mt-3"
					onClick={() => {
						setLoading(true);
						logOutUser(true);
					}}>
					LOGOUT
				</Button>
			</Container>
		);
	} else if (showLogin) {
		return (
			<Container
				initial={{ y: 50, zIndex: 0, opacity: 0 }}
				animate={{ y: 0, zIndex: 1, opacity: 1 }}
				exit={{ y: 50, zIndex: 0, opacity: 0 }}
				component={motion.div}>
				<Typography
					sx={{ mt: 2 }}
					variant="h3"
					component="div"
					gutterBottom>
					Please log in:
				</Typography>
				<form onSubmit={LoginSubmit}>
					<Stack
						direction="column"
						spacing={2}>
						<TextField
							autoComplete="off"
							label="Username"
							variant="outlined"
							name="uName"
							onChange={changeValue}
							value={uName}
							disabled={loading}
							fullWidth
						/>
						<TextField
							autoComplete="off"
							label="Password"
							variant="outlined"
							name="uPass"
							onChange={changeValue}
							value={uPass}
							type="password"
							disabled={loading}
							fullWidth
						/>
						<Button
							disabled={loading}
							variant="contained"
							type="submit"
							fullWidth
							color="primary">
							{!loading ? "Log in" : <CircularProgress size="1.5em" />}
						</Button>
					</Stack>
				</form>
				<Typography
					onClick={() => toggleLoginScreen()}
					component="div"
					gutterBottom
					style={{ marginTop: "1em", textDecoration: "underline" }}>
					Or create a new session instead.
				</Typography>
			</Container>
		);
	} else {
		return (
			<Container
				initial={{ y: 50, zIndex: 0, opacity: 0 }}
				animate={{ y: 0, zIndex: 1, opacity: 1 }}
				exit={{ y: 50, zIndex: 0, opacity: 0 }}
				component={motion.div}>
				<Typography
					sx={{ mt: 2 }}
					variant="h3"
					component="div"
					gutterBottom>
					Create session:
				</Typography>
				<form onSubmit={CreateSubmit}>
					<Stack
						direction="column"
						spacing={2}>
						<TextField
							autoComplete="off"
							label="Username"
							variant="outlined"
							name="uName"
							onChange={changeValue}
							value={uName}
							disabled={loading}
						/>
						<TextField
							autoComplete="off"
							label="Password"
							variant="outlined"
							name="uPass"
							onChange={changeValue}
							value={uPass}
							type="password"
							disabled={loading}
						/>
						<TextField
							autoComplete="off"
							label="Verify password"
							variant="outlined"
							name="uPassVerify"
							onChange={changeValue}
							value={uPassVerify}
							type="password"
							disabled={loading}
						/>
						<Button
							disabled={loading}
							variant="contained"
							type="submit"
							color="primary"
							className="mt-3">
							{!loading ? "Create session" : <CircularProgress size="1.5em" />}
						</Button>
					</Stack>
				</form>
				<Typography
					onClick={() => toggleLoginScreen()}
					component="div"
					gutterBottom
					style={{ marginTop: "1em", textDecoration: "underline" }}>
					Or log in instead.
				</Typography>
			</Container>
		);
	}
}
