import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function setCookie(cvalue) {
	Cookies.set("magicounter-session", cvalue, { path: "/", expires: 365 });
}

function deleteCookie(cname) {
	Cookies.remove(cname, { path: "/" });
}

function getCookie(cname) {
	return Cookies.get(cname);
}

export function getSession() {
	const sessionOld = getCookie("session");
	if (sessionOld) {
		setCookie(sessionOld);
		deleteCookie("session");
	}
	const magicounterSession = getCookie("magicounter-session");
	checkCookieValid(magicounterSession);
	return magicounterSession || false;
}

export function loginUser(sessionId) {
	setCookie(sessionId);
	if (window.location.pathname.includes("/login")) {
		window.location.assign("/");
	}
}

export function logOutUser(forceRefresh = false) {
	deleteCookie("session");
	deleteCookie("magicounter-session");
	if (!window.location.pathname.includes("/login") || forceRefresh) {
		window.location.assign("/login");
	}
	return false;
}

function checkCookieValid(sessionId) {
	if (!sessionId) {
		return logOutUser();
	}
	axios
		.get(`/api/sessions/authenticate/${sessionId}`)
		.then((res) => {
			if (res.data === "valid") {
				return true;
			} else {
				return logOutUser();
			}
		})
		.catch((err) => {
			console.log(err);
			return logOutUser();
		});
}
