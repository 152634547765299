import React, { useState, useEffect } from "react";
import axios from "axios";
import PlayerBox from "../components/playerBox.comp";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { Loader } from "../components/loader.comp";
import { useWebSocket } from "../WebSocketContext";
import { getSession } from "../helpers/auth.helper";
import { motion } from "framer-motion";

export default function GamePage(props) {
	const [loading, setLoading] = useState(true);
	const [players, setPlayers] = useState();
	const [playerStates, setPlayerStates] = useState({});
	const [starter, setStarter] = useState();
	const [redirect, setRedirect] = useState(false);
	const [numPlayers, setNumPlayers] = useState();
	const [matchId, setMatchId] = useState();
	const [decks, setDecks] = useState({});
	const navigate = useNavigate();
	const [messages, setMessages] = useState();
	const [sessionId, setSessionId] = useState(false);
	const { sendMessage, subscribeToMessages, monitorConnection } = useWebSocket();

	useEffect(() => {
		// Monitor the WebSocket connection
		const onConnect = () => {
			loadData();
		};

		monitorConnection(onConnect, () => {});
	}, [monitorConnection]);

	useEffect(() => {
		if (!messages) {
			return;
		}
		let incData = messages;
		if (typeof messages === "string") {
			incData = JSON.parse(incData);
		}
		if (incData.targetId !== undefined) {
			let newPlayerState = playerStates[incData.targetId];
			newPlayerState[incData.valueName] = newPlayerState[incData.valueName] + incData.mutation;
			setPlayerStates((prevState) => ({
				...prevState,
				[incData.targetId]: newPlayerState,
			}));
		} else if (incData.redirectTarget !== undefined) {
			if (incData.redirectTarget === "endGame") {
				setRedirect(true);
			}
		}
	}, [messages]);

	useEffect(() => {
		subscribeToMessages(setMessages);
		return () => {
			subscribeToMessages((message) => {}); // Remove the subscription
		};
	}, [subscribeToMessages]);

	function loadData() {
		axios.get("/api/matches/last").then((response) => {
			if (response.status !== 200 || !response.data || response.data["finished"]) {
				setRedirect(true);
			} else {
				setMatchId(response.data._id);
				setPlayers(response.data.players);
				setStarter(response.data.starter);
				setNumPlayers(response.data.players.length);
			}
		});
		axios.get("/api/decks/active").then((response) => {
			let deckDict = {};
			response.data.forEach((element) => {
				deckDict[element["_id"]] = element["deckNickName"];
			});
			setDecks(deckDict);
		});
	}

	useEffect(() => {
		if (players) {
			for (const player of players) {
				axios.get(`/api/mutations/playerstate/${matchId}/${player.player._id}`).then((response) => {
					const playerId = player.player._id;
					const content = response.data;
					setPlayerStates((prevState) => ({
						...prevState,
						[playerId]: content,
					}));
				});
			}
		}
	}, [players]);

	useEffect(() => {
		if (Object.keys(playerStates).length === numPlayers && decks && loading) {
			setLoading(false);
		}
		let deadPlayers = 0;
		Object.keys(playerStates).forEach((objectKey) => {
			if (playerStates[objectKey].dead) {
				deadPlayers++;
			}
		});
		if (deadPlayers >= numPlayers - 1) {
			setLoading(true);
			setRedirect(true);
		}
	}, [playerStates, decks, numPlayers]);

	useEffect(() => {
		if (redirect) {
			navigate("/start?state=ended");
		}
	}, [redirect]);

	useEffect(() => {
		setSessionId(getSession());
		loadData();
	}, []);

	function adjustValue(valueName, adjustor, playerId) {
		let newPlayerState = playerStates[playerId];
		newPlayerState[valueName] = newPlayerState[valueName] + adjustor;

		if (valueName !== "life" && valueName !== "poison" && valueName !== "tax" && valueName !== "dead") {
			sendUpdate("life", adjustor, playerId);
			newPlayerState["life"] = newPlayerState["life"] + adjustor;
		}
		setPlayerStates((prevState) => ({
			...prevState,
			[playerId]: newPlayerState,
		}));
		sendUpdate(valueName, adjustor, playerId);
	}

	function sendUpdate(valueName, adjustor, playerId) {
		const payload = {
			valueName: valueName,
			mutation: adjustor,
			matchId: matchId,
			playerId: playerId,
		};

		axios.post("/api/mutations/add", payload);
		sendMessage(
			JSON.stringify({
				valueName: valueName,
				mutation: adjustor,
				targetId: playerId,
				sessionId: sessionId,
				wsTimeStamp: Date.now(),
			})
		);
	}

	return (
		<Stack
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}
			sx={{
				display: "grid",
				gridAutoFlow: "row",
				gridAutoRows: "1fr",
				maxHeight: "calc(100dvh - 40px)",
				height: "calc(100dvh - 40px)",
			}}>
			<Loader loading={loading} />
			{players?.map((currentPlayer) => {
				return (
					<PlayerBox
						deckDict={decks}
						playerState={playerStates[currentPlayer.player._id]}
						adjustValue={adjustValue}
						match={matchId}
						player={currentPlayer}
						key={currentPlayer._id}
						starter={currentPlayer.player._id == starter._id}
					/>
				);
			})}
		</Stack>
	);
}
