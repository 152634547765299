import React, { useState, useEffect } from "react";
import DecksTable from "../stats/decksTable";
import Axios from "axios";
import { Container, Stack, Typography } from "@mui/material";
import PlayerToggle from "../components/toggleNumPlayers.comp";
import HistorySlider from "../components/historySlider.comp";
import ModeToggle from "../components/toggleStatsMode.comp";
import MatchCard from "../stats/matchCard";
import { Loader } from "../components/loader.comp";
import { motion } from "framer-motion";

export function StatsPage() {
	const [loading, setLoading] = useState(true);
	const [numPlayers, setNumPlayers] = useState(3);
	const [historyLimit, setHistoryLimit] = useState("All");
	const [statsMode, setStatsMode] = useState("players");
	const [preppedPlayerData, setPreppedPlayerData] = useState();
	const [preppedDeckData, setPreppedDeckData] = useState();
	const [allResults, setAllResults] = useState();
	const [matchesByNumPlayer, setMatchesByNumPlayer] = useState();

	useEffect(() => {
		if (preppedPlayerData === undefined || preppedDeckData === undefined) {
			return;
		}
		setLoading(false);
	}, [preppedPlayerData, preppedDeckData]);

	useEffect(() => {
		Axios.get("/api/players/results/get/all").then((response) => {
			setAllResults(response.data);
		});
	}, []);

	useEffect(() => {
		if (allResults === undefined) {
			return;
		}
		let matchDict = {};
		allResults.forEach((match) => {
			if (matchDict[match.players.length] === undefined) {
				matchDict[match.players.length] = [];
			}
			matchDict[match.players.length].push(match);
		});
		setMatchesByNumPlayer(matchDict);
	}, [allResults]);

	useEffect(() => {
		if (historyLimit === undefined || numPlayers === undefined || matchesByNumPlayer === undefined) {
			return;
		}
		preparePlayerData();
		prepareDeckData();
	}, [historyLimit, numPlayers, matchesByNumPlayer]);

	useEffect(() => {
		if (numPlayers && typeof numPlayers !== "number") {
			setNumPlayers(numPlayers.length);
		}
	}, [numPlayers]);

	function preparePlayerData() {
		let resultDict = {};
		const matches = historySlicer(matchesByNumPlayer[numPlayers]);
		matches?.forEach((match) => {
			match.players.forEach((player) => {
				if (resultDict[player.player._id] == null) {
					resultDict[player.player._id] = {
						name: player.player.playerName,
						wins: 0,
						games: 0,
						winrate: 0,
					};
				}
				if (player.result === 1) {
					resultDict[player.player._id]["wins"] += 1;
				}
				resultDict[player.player._id]["games"] += 1;
				if (resultDict[player.player._id]["wins"] !== 0) {
					resultDict[player.player._id]["winrate"] = Math.round(
						(resultDict[player.player._id]["wins"] / resultDict[player.player._id]["games"]) * 100
					);
				}
			});
		});
		let preppedData = [];
		for (const [key] of Object.entries(resultDict)) {
			preppedData.push(resultDict[key]);
		}
		setPreppedPlayerData(preppedData);
	}

	function prepareDeckData() {
		let resultDict = {};
		const matches = historySlicer(matchesByNumPlayer[numPlayers]);
		matches?.forEach((match) => {
			match.players.forEach((player) => {
				if (resultDict[player.deck._id] == null) {
					resultDict[player.deck._id] = {
						name: player.deck.deckNickName,
						wins: 0,
						games: 0,
						winrate: 0,
					};
				}
				if (player.result === 1) {
					resultDict[player.deck._id]["wins"] += 1;
				}
				resultDict[player.deck._id]["games"] += 1;
				if (resultDict[player.deck._id]["wins"] !== 0) {
					resultDict[player.deck._id]["winrate"] = Math.round(
						(resultDict[player.deck._id]["wins"] / resultDict[player.deck._id]["games"]) * 100
					);
				}
			});
		});
		let preppedData = [];
		for (const [key] of Object.entries(resultDict)) {
			preppedData.push(resultDict[key]);
		}
		setPreppedDeckData(preppedData);
	}

	function historySlicer(matches) {
		if (!matches || historyLimit === "All") {
			return matches;
		} else {
			const numSlicer = parseInt(historyLimit);
			let newArray = [];
			matches.slice(0, numSlicer).forEach((element) => {
				newArray.push(element);
			});
			return newArray;
		}
	}

	return (
		<Container
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}>
			<Loader loading={loading} />
			<Typography
				sx={{ mt: 2 }}
				variant="h4">
				Stats:
			</Typography>
			<ModeToggle
				statsMode={statsMode}
				setStatsMode={setStatsMode}
			/>

			{statsMode !== "matches" && <PlayerToggle setNumPlayers={setNumPlayers} />}
			{statsMode !== "matches" && <HistorySlider setHistoryLimit={setHistoryLimit} />}
			{statsMode !== "matches" && (
				<DecksTable inputData={statsMode === "players" ? preppedPlayerData : preppedDeckData} />
			)}
			{statsMode === "matches" && (
				<Stack
					container
					justifyContent="flex-start"
					spacing={1}
					alignItems="flex-start">
					{allResults.map((newMatch) => {
						return (
							<MatchCard
								match={newMatch}
								key={`matchcard-${newMatch._id}`}
							/>
						);
					})}
				</Stack>
			)}
		</Container>
	);
}
