import React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function StatsToggle({statsMode, setStatsMode }) {

	const handleChange = (event, newMode) => {
		if (newMode !== null && newMode !== statsMode) {
			setStatsMode(newMode);
		}
	};

	return (
			<ToggleButtonGroup
				sx={{mt:2, mb:2}}
				value={statsMode}
            exclusive
            fullWidth
				onChange={handleChange}

			>
				<ToggleButton value="matches" >
					Matches
				</ToggleButton>
				<ToggleButton  value="players" >
					Players
				</ToggleButton>
				<ToggleButton  value="decks" >
					Decks
				</ToggleButton>
			</ToggleButtonGroup>
	);
}


