import React, { Component, useState, useEffect } from "react";
import { Typography, Button, CardContent, CardActions, Card } from "@mui/material";
import { Link } from "react-router-dom";

function getResults(players) {
	let resultsObject = {};
	players.forEach((playerElement) => {
		let playerObject = { deckName: playerElement.deck.deckNickName, playerName: playerElement.player.playerName };
		resultsObject[playerElement.result] = playerObject;
	});
	return resultsObject;
}

function getTimes(matchObject) {
	let timeObject = {};
	let offset = new Date().getTimezoneOffset();
	let oldStartTime = new Date(matchObject.createdAt);
	let newStartTime = new Date(oldStartTime.getTime() + offset * 1000);
	let oldEndTime = new Date(matchObject.updatedAt);
	let newEndTime = new Date(oldEndTime.getTime() + offset * 1000);
	let matchDuration = (newEndTime.getTime() - newStartTime.getTime()) / 1000;

	timeObject["matchStartTime"] = `${newStartTime.getHours()}:${newStartTime
		.getMinutes()
		.toString()
		.padStart(2, "0")}`;

	timeObject["matchEndTime"] = `${newEndTime.getHours()}:${newEndTime.getMinutes().toString().padStart(2, "0")}`;
	timeObject["matchDuration"] = matchDuration;
	timeObject["matchDate"] = matchObject.createdAt.slice(0, 10);
	return timeObject;
}

export default function MatchCard(props) {
	const matchId = props.match._id;
	const players = props.match.players;
	const matchPageLink = `/match/${matchId}`;
	const timeInfo = getTimes(props.match);
	const results = getResults(players);

	if (props.match.finished) {
		return (
			<Card sx={{ width: "100%" }}>
				<CardContent>
					<Typography
						color="textSecondary"
						gutterBottom>
						{timeInfo.matchDate} : {timeInfo.matchStartTime} - {timeInfo.matchEndTime}
					</Typography>
					<Typography
						color="textSecondary"
						gutterBottom>
						{new Date(timeInfo.matchDuration * 1000).toISOString().substr(11, 5)}
					</Typography>
					<Typography
						variant="h5"
						component="h2">
						{results[1].playerName} won
					</Typography>
					<Typography color="textSecondary">playing {results[1].deckName}</Typography>
					<Typography
						variant="body2"
						component="p">
						{Object.keys(results).map((playerKey) => {
							if (playerKey > 1) {
								return (
									<span key={playerKey}>
										{playerKey}: {results[playerKey].playerName} - {results[playerKey].deckName}
										<br />
									</span>
								);
							}
						})}
					</Typography>
				</CardContent>
				<CardActions>
					<Button
						fullWidth
						component={Link}
						to={matchPageLink}
						variant="outlined"
						size="small">
						Go to match
					</Button>
				</CardActions>
			</Card>
		);
	} else {
		return (
			<Card
				className="matchCard"
				style={{ minWidth: "220px" }}>
				<CardContent>
					<Typography
						color="textSecondary"
						gutterBottom>
						{timeInfo.matchDate} : {timeInfo.matchStartTime} -
					</Typography>
					<Typography
						variant="h5"
						component="h2">
						In progress
					</Typography>
				</CardContent>
				<CardActions>
					<Button
						fullWidth
						component={Link}
						to={matchPageLink}
						variant="outlined"
						size="small">
						Go to match
					</Button>
				</CardActions>
			</Card>
		);
	}
}
