import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import AddButton from "../components/addDeckButton.comp";
import {
	Switch,
	Button,
	Container,
	Table,
	TableContainer,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Backdrop,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import colorBlock from "../styling/colorBlock";
import { Loader } from "../components/loader.comp";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

export function DeckPage(props) {
	const [deckList, setDeckList] = useState();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/api/decks/")
			.then((response) => {
				setDeckList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (deckList) {
			const newDeckList = Array.from(deckList);
			newDeckList.sort((a, b) => {
				return b.deckActive - a.deckActive;
			});
			if (JSON.stringify(newDeckList) !== JSON.stringify(deckList)) {
				setDeckList(newDeckList);
			}
			setLoading(false);
		}
	}, [deckList]);

	return (
		<Container
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}>
			<Loader loading={loading} />
			<Typography
				sx={{ mt: 2 }}
				variant="h4">
				Decks:
			</Typography>

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow style={{ marginTop: "1em", paddingBottom: "16px" }}>
							<TableCell style={{ paddingLeft: "1em", fontSize: "1.5em", width: "60%" }}>
								Commander
							</TableCell>
							<TableCell style={{ fontSize: "1.5em", width: "40%" }}>Active</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{deckList?.map((activeDeck) => {
							return (
								<TableRow key={activeDeck._id}>
									<TableCell
										size="small"
										onClick={() => {
											navigate(`/decks/edit/${activeDeck._id}`);
										}}
										style={{ width: "50%" }}>
										<Stack direction="row">
											<Typography
												sx={{ flexDirection: "row", display: "flex" }}
												variant="h6">
												<Stack
													style={colorBlock(activeDeck.deckColors, {
														width: "50px",
														height: "100%",
														borderRadius: "5px",
														border: "solid black 1px",
														marginRight: ".5em",
													})}
												/>
												{activeDeck.deckNickName}
											</Typography>
										</Stack>
									</TableCell>

									<TableCell
										size="small"
										style={{ width: "25%" }}>
										<Switch
											name="checkedA"
											inputProps={{
												"aria-label": "secondary checkbox",
											}}
											color="primary"
											checked={activeDeck.deckActive}
											onChange={() => {
												this.toggleDeck(activeDeck._id);
											}}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				sx={{ mt: 2 }}
				variant="contained"
				fullWidth
				onClick={() => navigate("/decks/new")}>
				Add Deck
			</Button>
		</Container>
	);
}
