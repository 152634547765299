import React from "react";
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from "@mui/material";

function DeckTypeSelector({ deckType, deckSubType, selectDeckType }) {
	return (
		<FormControl>
			<InputLabel>Deck Type</InputLabel>
			<Select
				label="Deck Type"
				value={deckType ? `${deckType}-${deckSubType}` : ""}
				onChange={selectDeckType}>
				<ListSubheader>Aggro</ListSubheader>

				<MenuItem value={"aggro-linearAggro"}>Linear Aggro</MenuItem>
				<MenuItem value={"aggro-swarm"}>Swarm</MenuItem>
				<MenuItem value={"aggro-bigAggro"}>Big Aggro</MenuItem>
				<MenuItem value={"aggro-voltronAggro"}>Voltron Aggro</MenuItem>
				<ListSubheader>Midrange</ListSubheader>

				<MenuItem value={"midrange-pain"}>Pain</MenuItem>
				<MenuItem value={"midrange-goodstuff"}>Goodstuff</MenuItem>
				<MenuItem value={"midrange-trueMidrange"}>True Midrange</MenuItem>
				<MenuItem value={"midrange-toolbox"}>Toolbox</MenuItem>
				<MenuItem value={"midrange-groupHug"}>Group Hug</MenuItem>
				<MenuItem value={"midrange-metaHate"}>Meta Hate</MenuItem>
				<MenuItem value={"midrange-voltronControl"}>Voltron Control</MenuItem>
				<ListSubheader>Control</ListSubheader>

				<MenuItem value={"control-thiefControl"}>Thief Control</MenuItem>
				<MenuItem value={"control-chaos"}>Chaos</MenuItem>
				<MenuItem value={"control-stax"}>Stax</MenuItem>
				<MenuItem value={"control-pillowFort"}>Pillow Fort</MenuItem>
				<MenuItem value={"control-drawGo"}>Draw-Go</MenuItem>
				<MenuItem value={"control-comboControl"}>Combo Control</MenuItem>
				<ListSubheader>Combo</ListSubheader>

				<MenuItem value={"combo-fullCombo"}>Full Combo</MenuItem>
				<MenuItem value={"combo-storm"}>Storm</MenuItem>
				<MenuItem value={"combo-aggroCombo"}>Aggro Combo</MenuItem>
			</Select>
		</FormControl>
	);
}

export default DeckTypeSelector;
