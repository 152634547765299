import { useSpring, animated } from "@react-spring/web";
import React, { useState } from "react";

export default function Number(props) {
	const [flip] = useState(false);
	const { number } = useSpring({
		reset: false,
		reverse: flip,
		from: { number: 0 },
		number: props.value,
		delay: 0,
		config: { mass: 1, tension: 500, friction: 25, clamp: true },
	});

	return <animated.div className={props.className}>{number.to((n) => n.toFixed(0))}</animated.div>;
	// return <div>{props.value}</div>;
}
