import React from "react";
import { AdjustButton } from "./AdjustButton.comp";
import { Typography, Button, Stack } from "@mui/material";

export function PlayerLifeColumn({ adjustValue, playerId, value, displayValue, targetAttribute }) {
	const borderString = "solid white 1px";
	return (
		<Stack
			sx={{ flexGrow: targetAttribute ? 1 : 0, justifyContent: "center", pl: 1, pr: 1 }}
			direction="column">
			<Button
				onClick={() => {
					adjustValue(targetAttribute || "life", value, playerId);
				}}
				sx={{
					color: "inherit",
					p: "0px",
					minWidth: "0px",
					fontSize: "inherit",
					justifyContent: "center",
				}}
				size="small">
				<Typography
					sx={{
						textAlign: "center",
						border: borderString,
						borderTopRightRadius: ".15em",
						borderTopLeftRadius: ".15em",
						backgroundColor: "rgba(255,255,255,0.1)",
						width: "100%",
					}}
					variant="h2">
					<AdjustButton direction="up" />
				</Typography>
			</Button>
			<Typography
				sx={{
					textAlign: "center",
					border: borderString,
					borderTop: "none",
					borderBottom: "none",
					backgroundColor: "rgba(255,255,255,0.1)",
				}}
				variant="h3">
				{displayValue || value || 0}
			</Typography>
			<Button
				onClick={() => {
					adjustValue(targetAttribute || "life", -value, playerId);
				}}
				sx={{
					color: "inherit",
					p: "0px",
					minWidth: "0px",
					fontSize: "inherit",
				}}
				size="small">
				<Typography
					sx={{
						textAlign: "center",
						border: borderString,
						borderBottomRightRadius: ".15em",
						borderBottomLeftRadius: ".15em",
						backgroundColor: "rgba(255,255,255,0.1)",
						width: "100%",
					}}
					variant="h2">
					<AdjustButton direction="down" />
				</Typography>
			</Button>
		</Stack>
	);
}
