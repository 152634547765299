import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export function Loader(props) {
	return (
		<Backdrop
			sx={{ color: "#fff", zIndex: 9999 }}
			open={props.loading}>
			<CircularProgress />
		</Backdrop>
	);
}
