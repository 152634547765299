import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";

export function PlayerKillBox({ playerName, killPlayer, nextPage }) {
	const [timeLeft, setTimeLeft] = useState(100);

	useEffect(() => {
		let interval;

		if (timeLeft > 0) {
			interval = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 10);
		}

		return () => {
			clearInterval(interval);
		};
	}, [, timeLeft]);

	useEffect(() => {
		setTimeLeft(100);
	}, []);

	return (
		<motion.div
			key="player-kill-box"
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			style={{
				flexGrow: "1",
				display: "flex",
				backgroundSize: "cover",
				backgroundImage: `url('${"./murder.jpg" || ""}')`,
				backgroundPosition: "center",
				borderTop: "solid gray 1px",
			}}>
			{/* not in love with this approach but it seems the easiest way to get the background blurring to work */}

			<Stack
				sx={{
					backdropFilter: "blur(5px) grayscale(0.7) contrast(01)",
					boxShadow: "rgb(0, 0, 0) 0px 0px 300px 50px inset",
					flexGrow: "1",
					display: "flex",
				}}
				direction="column">
				<Typography
					variant="h3"
					sx={{ flexGrow: "1", alignSelf: "center", display: "flex", alignItems: "center" }}>
					Is {playerName} dead?
				</Typography>

				<Stack
					sx={{ flexGrow: "0", p: 2 }}
					spacing={2}
					direction="row">
					<Button
						disabled={timeLeft !== 0}
						fullWidth
						size="large"
						sx={{
							backgroundColor: timeLeft !== 0 ? "rgba(255, 255, 255, 0.05)" : "",
							background:
								timeLeft !== 0
									? `linear-gradient(90deg, rgba(255, 255, 255, 0.05) ${timeLeft}%, rgba(255, 255, 255, 0.0) ${timeLeft}%)`
									: "",
						}}
						color="primary"
						variant="contained"
						onClick={killPlayer}>
						YES
					</Button>
					<Button
						fullWidth
						size="large"
						variant="outlined"
						onClick={nextPage}>
						NO
					</Button>
				</Stack>
			</Stack>
		</motion.div>
	);
}
