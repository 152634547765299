import React, { useEffect } from "react";
import colorBlock from "../styling/colorBlock";
import { Stack, Checkbox } from "@mui/material";

export function ColorSelection({ colors, toggleColor }) {
	const colorOptions = ["white", "blue", "black", "red", "green"];

	return (
		<Stack
			direction="row"
			style={{ display: "flex" }}>
			{colorOptions.map((color) => {
				return (
					<div
						style={{ flexGrow: 1, textAlign: "center", justifyContent: "center", alignItems: "center" }}
						className="deckNameDiv small"
						key={color}>
						<Checkbox
							color="primary"
							checked={colors ? Object.values(colors).includes(color) : false}
							name={color}
							onChange={toggleColor}
						/>
						<div
							className="colorBlock"
							style={colorBlock([color])}
						/>
						{color.charAt(0).toUpperCase() + color.slice(1)}
					</div>
				);
			})}
		</Stack>
	);
}
