import React, { useState, useEffect } from "react";
import { InputLabel, MenuItem, FormControl, Select, Stack } from "@mui/material";
import colorBlock from "../styling/colorBlock";

export default function PlayerSelect(props) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (props && props.playerList && props.playerNumber !== undefined && props.deckList) {
			setLoading(false);
		}
	}, [props]);

	function handlePlayerChange(event) {
		props.handleChange(props.playerNumber, "player", event.target.value);
	}
	const handleDeckChange = (event) => {
		props.handleChange(props.playerNumber, "deck", event.target.value);
	};
	return (
		!loading && (
			<Stack
				spacing={2}
				sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}
				direction="row">
				<FormControl fullWidth>
					<InputLabel id="playerNameLabel">Player</InputLabel>
					<Select
						labelId="playerNameLabel"
						id="playerNameSelect"
						value={props?.info?.player || ""}
						onChange={handlePlayerChange}
						label="Player"
						native={false} // Use native={false} for MenuItems
					>
						{props.playerList.map((player) => (
							<MenuItem
								key={player._id}
								value={player._id}>
								{player.playerName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth>
					<InputLabel id="deckNameLabel">Deck</InputLabel>
					<Select
						className="deckSelector"
						disabled={props.allRandom}
						labelId="deckNameLabel"
						id="deckNameSelect"
						value={props?.allRandom ? "random" : props?.info?.deck || ""}
						onChange={handleDeckChange}
						label="Deck">
						{props?.allRandom ? (
							<option
								key={`RANDOM${props.playerNumber}`}
								value="random">
								RANDOM
							</option>
						) : (
							""
						)}
						{props.deckList.map((deck) => (
							<MenuItem
								key={deck._id}
								value={deck._id}>
								<div className="deckNameDiv">
									<div
										className="colorBlock"
										style={colorBlock(deck.deckColors)}
									/>
									{deck.deckNickName}
								</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Stack>
		)
	);
}
