import {
	Container,
	Button,
	Backdrop,
	CircularProgress,
	ToggleButton,
	ToggleButtonGroup,
	TextField,
	Select,
	MenuItem,
	Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import PlayerSelect from "../components/playerSelect.comp";
import PlayerToggle from "../components/toggleNumPlayers.comp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../components/loader.comp";
import { getSession } from "../helpers/auth.helper";
import { useWebSocket } from "../WebSocketContext";
import { motion } from "framer-motion";

export function StartPage(props) {
	const [numPlayers, setNumPlayers] = useState([0, 1, 2]);
	const [deckList, setDeckList] = useState();
	const [playerList, setPlayerList] = useState();
	const [players, setPlayers] = useState({});
	const [valid, setValid] = useState(false);
	const [loading, setLoading] = useState(true);
	const [allRandom, setAllRandom] = useState(false);
	const [searchParams] = useSearchParams();
	const [allowVisual, setAllowVisual] = useState(false);
	const [sessionId, setSessionId] = useState(false);
	const navigate = useNavigate();
	const { sendMessage } = useWebSocket();

	function handleChange(playerNumber, valueName, newValue) {
		let oldPlayers = { ...players };
		if (oldPlayers[playerNumber] === undefined) {
			oldPlayers[playerNumber] = {};
		}
		oldPlayers[playerNumber][valueName] = newValue;
		setPlayers(oldPlayers);
	}

	function checkFrom() {
		const fromState = searchParams.get("state");
		if (fromState !== "ended") {
			return;
		}
		sendMessage(JSON.stringify({ redirectTarget: "endGame", sessionId: sessionId }));
	}

	function getPrevious() {
		axios.get("/api/matches/last").then((response2) => {
			axios
				.get("/api/decks/active")
				.then((response) => {
					if (response2) {
						let activeDeckList = [];
						response.data.forEach((activeDeck) => {
							activeDeckList.push(activeDeck._id);
						});
						let prevPlayers = {};
						const prevArray = [...Array(response2.data.players.length).keys()];
						let smallestArray = prevArray;
						if (numPlayers.length < smallestArray.length) {
							smallestArray = numPlayers;
						}
						smallestArray.forEach((playerNumber) => {
							let prevPlayer = response2.data.players[playerNumber].player._id;
							let prevDeck = response2.data.players[playerNumber].deck._id;
							prevPlayers[playerNumber] = {};
							prevPlayers[playerNumber]["player"] = prevPlayer;
							if (activeDeckList.includes(prevDeck)) {
								prevPlayers[playerNumber]["deck"] = prevDeck;
							}
						});
						setPlayers(prevPlayers);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		});
	}

	function getPlayers() {
		axios.get("/api/players").then((response) => {
			setPlayerList(response.data);
		});
	}

	function getDecks() {
		axios.get("/api/decks/active").then((response) => {
			setDeckList(response.data);
		});
	}

	function startGame() {
		setLoading(true);
		let payLoad = { players: [] };
		numPlayers.forEach((elementNumber) => {
			let element = players[elementNumber];
			let playerDeck = element["deck"];
			if (allRandom) {
				let playerDecks = [];
				deckList.forEach((deckElement) => {
					if (deckElement.player._id === element["player"]) {
						playerDecks.push(deckElement._id);
					}
				});
				playerDeck = playerDecks[Math.floor(Math.random() * playerDecks.length)];
			}
			payLoad["players"].push({ deck: playerDeck, player: element["player"] });
		});
		axios.post("/api/matches/add", payLoad).then(() => {
			sendMessage(JSON.stringify({ redirectTarget: "startGame", sessionId: sessionId }));
			navigate("/game");
		});
	}

	function checkValidity() {
		const validPlayers = numPlayers.every(isValidPlayer);
		if (!validPlayers) {
			setValid(false);
			return;
		}
		let uniques = [];
		for (const playerNum in numPlayers) {
			const { playerDeckId, playerId } = getPlayerInfo(playerNum);
			if (uniques.includes(playerDeckId)) {
				setValid(false);
				return;
			}
			uniques.push(playerDeckId);
			if (uniques.includes(playerId)) {
				setValid(false);
				return;
			}
			uniques.push(playerId);
		}
		setValid(true);
	}

	function getPlayerInfo(playerNumber) {
		const playerDeckId = players[playerNumber]["deck"];
		const playerId = players[playerNumber]["player"];
		return { playerDeckId, playerId };
	}

	function isValidPlayer(playerNumber) {
		if (players[playerNumber] === undefined) {
			return false;
		} else if (players[playerNumber]["deck"] === undefined && !allRandom) {
			return false;
		} else if (players[playerNumber]["player"] === undefined) {
			return false;
		} else {
			return true;
		}
	}

	function isGameActive(checkFirst) {
		axios.get("/api/matches/last").then((response) => {
			if (response.data["finished"] === false) {
				navigate("/game");
			} else {
				setAllowVisual(true);
				if (checkFirst) {
					startGame();
				}
			}
		});
	}

	useEffect(() => {
		setSessionId(getSession());
		checkFrom();
		isGameActive(false);
		getPlayers();
		getDecks();
		getPrevious();
	}, []);

	useEffect(() => {
		if (deckList && playerList) {
			setLoading(false);
		}
	}, [deckList, playerList]);

	useEffect(() => {
		checkValidity();
	}, [players, allRandom]);

	return (
		<>
			<Loader loading={loading} />

			{!loading && (
				<Container
					initial={{ y: 50, zIndex: 0, opacity: 0 }}
					animate={{ y: 0, zIndex: 1, opacity: 1 }}
					exit={{ y: 50, zIndex: 0, opacity: 0 }}
					component={motion.div}>
					<Typography
						sx={{ mt: 2, mb: 2 }}
						variant="h4">
						Start game:
					</Typography>
					{allowVisual && (
						<>
							<PlayerToggle
								numPlayers={numPlayers}
								setNumPlayers={setNumPlayers}
							/>
							{numPlayers.map((newPlayer) => (
								<PlayerSelect
									playerList={playerList}
									deckList={deckList}
									handleChange={handleChange}
									playerNumber={newPlayer}
									info={players[newPlayer]}
									key={newPlayer}
									allRandom={allRandom}
								/>
							))}
							<ToggleButtonGroup
								value={allRandom}
								exclusive
								onChange={() => setAllRandom(!allRandom)}
								aria-label="text alignment"
								sx={{ width: "100%", pb: 2, pt: 4 }}>
								<ToggleButton
									style={{ width: "50%" }}
									value={false}
									aria-label="left aligned">
									Players choice
								</ToggleButton>
								<ToggleButton
									style={{ width: "50%" }}
									value={true}
									aria-label="centered">
									All random
								</ToggleButton>
							</ToggleButtonGroup>
							<Button
								fullWidth
								sx={{ mt: 2 }}
								className="startButton"
								variant="contained"
								color="primary"
								disabled={loading || !valid}
								onClick={() => {
									setLoading(true);
									isGameActive(true);
								}}>
								{allRandom ? "START RANDOM" : "START"}
							</Button>
						</>
					)}
				</Container>
			)}
		</>
	);
}
