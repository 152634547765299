import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CentralButton } from "./centralButton.comp";
import { PlayerLifeBox } from "./PlayerLifeBox.comp.js";
import { PlayerKillBox } from "./PlayerKillBox.comp";
import { CommanderBox } from "./CommanderBox.comp";

export default function PlayerBox(props) {
	const navigate = useNavigate();
	const playerName = props.player["player"]["playerName"];
	const deckName = props.player["deck"]["deckName"];
	const deckNickName = props.player["deck"]["deckNickName"];
	const playerId = props.player["player"]["_id"];
	const [commands, setCommands] = useState();
	const [gameOver, setGameOver] = useState(false);
	const [dead, setDead] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);

	const pages = {
		0: (
			<PlayerKillBox
				playerName={playerName}
				killPlayer={killPlayer}
				nextPage={nextPage}
			/>
		),
		1: (
			<PlayerLifeBox
				adjustValue={props.adjustValue}
				playerId={playerId}
				deckName={deckName}
				starter={props.starter}
				playerState={props.playerState}
				toggleCommand={toggleCommand}
				playerName={playerName}
				nextPage={nextPage}
			/>
		),
		2: (
			<CommanderBox
				prevPage={prevPage}
				commands={commands}
				playerState={props.playerState}
				playerId={playerId}
				adjustValue={props.adjustValue}
				deckDict={props.deckDict}
			/>
		),
	};

	function nextPage() {
		if (pageNumber < 2) {
			setPageNumber(pageNumber + 1);
		}
	}

	function prevPage() {
		if (pageNumber >= 0) {
			setPageNumber(pageNumber - 1);
		}
	}

	useEffect(() => {
		if (props.player && props.playerState) {
			if (props?.player["result"]) {
				setDead(true);
			}
			let commands = [];
			Object.keys(props?.playerState).forEach((curState) => {
				if (curState !== "life" && curState !== "poison" && curState !== "tax" && curState !== "dead") {
					commands.push(curState);
				}
			});
			setCommands(commands);
			for (const [key, value] of Object.entries(props?.playerState)) {
				if (key !== "tax" && key !== "dead" && value <= 0) {
					setPageNumber(0);
				}
				if (key === "dead" && value !== false) {
					setDead(true);
				}
			}
		}
	}, [props]);

	useEffect(() => {
		if (gameOver) {
			navigate("/");
		}
	}, [gameOver]);

	function toggleCommand() {
		setPageNumber(2);
	}

	function killPlayer() {
		const newUri = `/api/matches/killplayer/${props.match}/${props.player.player._id}`;
		props.adjustValue("dead", true, props.player["player"]["_id"]);
		Axios.get(newUri).then((response) => {
			if (response.data === "Game ended") {
				setGameOver(true);
			} else {
				setDead(true);
			}
		});
	}

	useEffect(() => {
		if (gameOver) {
			navigate("/start");
		}
	}, [gameOver]);

	return !dead && pages[pageNumber];
}
