import React, { useState, useEffect } from "react";
import { ReactComponent as InfectIcon } from "../styling/images/infect-w.svg";
import { ReactComponent as CommandIcon } from "../styling/images/command-w.svg";
import { ReactComponent as InfectIconGray } from "../styling/images/infect-w20.svg";
import { ReactComponent as CommandIconGray } from "../styling/images/command-w20.svg";
import { ReactComponent as CommandDamageIcon } from "../styling/images/tap-w.svg";
import { Button, Badge } from "@mui/material";
const containerStyle = {
	width: "50px",
	maxWidth: "50px",
	height: "50px",
	maxHeight: "50px",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};
const iconDict = {
	poison: <InfectIcon style={containerStyle} />,
	tax: <CommandIcon style={containerStyle} />,
	commandDamage: <CommandDamageIcon style={containerStyle} />,
};

export function CentralButton({ active, currentValue, onClick, value }) {
	const [displayValue, setDisplayValue] = useState();

	useEffect(() => {
		if (!currentValue || !active) {
			return;
		}
		if (currentValue === "N/a") {
			setDisplayValue(undefined);
		} else if (active === "poison") {
			setDisplayValue(10 - currentValue !== 0 ? 10 - currentValue : "");
		} else if (active === "tax") {
			setDisplayValue(currentValue !== 0 ? currentValue : "");
		} else {
			setDisplayValue(undefined);
		}
	}, [currentValue, active]);

	return (
		<Button
			onClick={onClick}
			size="small"
			style={containerStyle}>
			<Badge
				size="large"
				sx={{ "& .MuiBadge-badge": { fontSize: "2em", height: "25px" } }}
				overlap="circular"
				badgeContent={displayValue || 0}
				color="primary">
				{iconDict[active]}
			</Badge>
		</Button>
	);
}
