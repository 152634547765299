import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Button, Backdrop, Paper, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export default function MatchPage() {
	const [matchId, setMatchId] = useState();
	const { matchIdParam } = useParams();
	const [deleting, setDeleting] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (matchIdParam) {
			setMatchId(matchIdParam);
		}
	}, [matchIdParam]);

	function deleteMatch() {
		axios.delete(`/api/matches/delete/${matchId}`).then(() => {
			setDeleting(false);
			navigate("/stats");
		});
	}

	return (
		<>
			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={deleting}>
				<Paper>
					<Grid
						style={{
							paddingTop: "1em",
							paddingBottom: "1em",
							textAlign: "center",
						}}
						container
						spacing={2}>
						<Grid
							item
							xs={12}>
							Are you sure you want to delete this match?
						</Grid>
						<Grid
							item
							xs={6}>
							<Button
								variant="outlined"
								onClick={() => {
									setDeleting(false);
								}}>
								Cancel
							</Button>
						</Grid>
						<Grid
							item
							xs={6}>
							<Button
								variant="contained"
								onClick={deleteMatch}>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Paper>
			</Backdrop>
			<Container sx={{ mt: 2 }}>
				<Stack
					direction="column"
					spacing={2}>
					<Typography variant="h4">Viewing match:</Typography>
					<Typography variant="h6">{matchId}</Typography>
					<Stack
						spacing={2}
						direction="row">
						<Button
							fullWidth
							onClick={() => {
								navigate(-1);
							}}
							variant="contained">
							back
						</Button>
						<Button
							color="error"
							fullWidth
							onClick={() => {
								setDeleting(true);
							}}
							variant="contained">
							Delete match
						</Button>
					</Stack>
				</Stack>
			</Container>
		</>
	);
}
