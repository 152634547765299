import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Typography, Button } from "@mui/material";

export function AdjustButton({ direction }) {
	return (
		<Typography
			variant="inherit"
			style={{ zIndex: 2, alignSelf: "center", display: "flex", justifyContent: "center" }}>
			{direction === "up" ? <AddIcon fontSize="inherit" /> : <RemoveIcon fontSize="inherit" />}
		</Typography>
	);
}
