import React from "react";
import Number from "./number.comp.js";
import { CentralButton } from "./centralButton.comp";
import { Stack, Typography } from "@mui/material";

export function PlayerMidsection({ deckName, playerName, playerState, adjustValue, playerId, toggleCommand, starter }) {
	return (
		<Stack sx={{ flexGrow: "20", textAlign: "center" }}>
			<Typography sx={{ flexGrow: "1", mt: 1 }}>{deckName || "Loading..."}</Typography>
			<Typography
				variant="h5"
				sx={{ flexGrow: "1", textDecoration: starter && "underline" }}>
				{playerName || "Loading..."}
			</Typography>
			<Typography
				variant="h3"
				sx={{ flexGrow: "5", display: "flex", alignSelf: "center", alignItems: "center" }}>
				<Number value={playerState?.life} />
			</Typography>
			<Stack
				sx={{ flexGrow: "0", justifyContent: "center", mb: 1 }}
				direction="row">
				<CentralButton
					active="tax"
					value={1}
					onClick={() => adjustValue("tax", 1, playerId)}
					currentValue={playerState?.tax}
				/>
				<CentralButton
					active="poison"
					value={-1}
					onClick={() => adjustValue("poison", -1, playerId)}
					currentValue={playerState?.poison}
				/>
				<CentralButton
					active="commandDamage"
					value={-1}
					onClick={toggleCommand}
					currentValue="0"
				/>
			</Stack>
		</Stack>
	);
}
