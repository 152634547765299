import { Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import React from "react";
import { PlayerLifeColumn } from "./PlayerLifeColumn.comp";
import ReplyIcon from "@mui/icons-material/Reply";

export function CommanderBox({ prevPage, commands, playerState, playerId, adjustValue, deckDict }) {
	return (
		<motion.div
			key="player-kill-box"
			initial={{ x: 50, opacity: 0 }}
			animate={{ x: 0, zIndex: 1, opacity: 1 }}
			exit={{ x: -50, zIndex: 0, opacity: 0 }}
			style={{
				flexGrow: "1",
				display: "flex",
				borderTop: "solid gray 1px",
			}}>
			<Stack
				direction="row"
				sx={{ display: "flex", flexGrow: 1 }}>
				<Typography
					onClick={prevPage}
					sx={{ alignSelf: "center", flexGrow: 0, fontSize: "15dvw" }}>
					<ReplyIcon fontSize="inherit" />
				</Typography>
				{commands?.map((command) => {
					return (
						<Stack
							sx={{ flexGrow: 1 }}
							direction="column">
							<Typography
								variant="h4"
								sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
								{deckDict[command]}
							</Typography>
							<PlayerLifeColumn
								key={command}
								adjustValue={adjustValue}
								playerId={playerId}
								value={1}
								displayValue={playerState[command]}
								targetAttribute={command}
							/>
						</Stack>
					);
				})}
			</Stack>
		</motion.div>
	);
}
