import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { getSession } from "./helpers/auth.helper";

// Create a WebSocket context
const WebSocketContext = createContext();

// Custom hook to access the WebSocket context
export const useWebSocket = () => useContext(WebSocketContext);

// WebSocket provider component
export function WebSocketProvider({ children }) {
	const socketUrl = `http${window.location.protocol === "http:" ? "" : "s"}://${window.location.hostname}:8080`;
	const [socket, setSocket] = useState(null);
	const sessionId = getSession();

	useEffect(() => {
		if (socket && socket.connected) {
			console.log("asdasdasd");
			localStorage.setItem("magicounter-reloads", "0");
		}
	}, [socket]);

	useEffect(() => {
		const newSocket = io(socketUrl, {
			query: { sessionId: sessionId }, // Pass cookies as query parameter
		});

		setSocket(newSocket);

		return () => {
			newSocket.disconnect();
		};
	}, [sessionId]);

	const sendMessage = (message) => {
		if (socket) {
			socket.emit("message", message);
		}
	};

	const subscribeToMessages = (callback) => {
		if (socket) {
			socket.on("message", callback);
		}
	};
	const isConnectionOpen = () => {
		return socket && socket.connected;
	};
	const monitorConnection = (onConnect, onDisconnect) => {
		if (socket) {
			socket.on("connect", onConnect);
			socket.on("connect", () => {
				localStorage.setItem("magicounter-reloads", "0");
			});

			socket.on("disconnect", onDisconnect);
		} else {
			onDisconnect();
		}
	};

	const value = {
		sendMessage,
		subscribeToMessages,
		monitorConnection,
		isConnectionOpen,
	};

	return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>;
}
