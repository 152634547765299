export default function colorBlock(colors, style = {}) {
	const colorDict = {
		green: "rgb(86, 140, 86)",
		red: "rgb(224, 107, 107)",
		blue: "rgb(122, 153, 244)",
		black: "rgb(42, 45, 56)",
		white: "rgb(255, 225, 126)",
		colorless: "#cccccc",
	};
	if (colors?.length === 0) {
		style["backgroundColor"] = colorDict["colorless"];
		return style;
	} else if (colors?.length === 1) {
		style["backgroundColor"] = colorDict[colors[0]];
		return style;
	} else if (colors?.length === 2) {
		style["background"] = `linear-gradient(90deg, ${colorDict[colors[0]]} 50%, ${colorDict[colors[1]]} 50%)`;
		return style;
	} else if (colors?.length === 3) {
		style["background"] = `linear-gradient(90deg, ${colorDict[colors[0]]} 33%, ${colorDict[colors[1]]} 33%, ${
			colorDict[colors[1]]
		} 66%, ${colorDict[colors[2]]} 66%)`;
		return style;
	} else if (colors?.length === 4) {
		style["background"] = `linear-gradient(90deg, ${colorDict[colors[0]]} 25%, ${colorDict[colors[1]]} 25%, ${
			colorDict[colors[1]]
		} 50%, ${colorDict[colors[2]]} 50%, ${colorDict[colors[2]]} 75%, ${colorDict[colors[3]]} 75%)`;
		return style;
	} else if (colors?.length === 5) {
		style["background"] = `linear-gradient(90deg, ${colorDict[colors[0]]} 20%, ${colorDict[colors[1]]} 20%, ${
			colorDict[colors[1]]
		} 40%, ${colorDict[colors[2]]} 40%, ${colorDict[colors[2]]} 60%, ${colorDict[colors[3]]} 60%, ${
			colorDict[colors[3]]
		} 80%, ${colorDict[colors[4]]} 80%)`;
		return style;
	}
}
