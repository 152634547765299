import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

function createNumberList(amount) {
	const numberList = [];
	for (let i = 0; i < amount; i++) {
		numberList.push(i);
	}
	return numberList;
}

export default function PlayerToggle(props) {
	const [players, setPlayers] = useState("3");

	const handleChange = (event, newPlayers) => {
		if (newPlayers !== null && newPlayers !== players) {
			const numPlayers = parseInt(newPlayers);
            setPlayers(newPlayers);
			props.setNumPlayers(createNumberList(numPlayers));
		}
	};

	return (
        <ToggleButtonGroup
            sx={{mb:2}}
			value={players}
            exclusive
            fullWidth
			onChange={handleChange}
			className="toggleBar"
			aria-label="Number of Players">
            {[2,3,4].map((num) => (

                <ToggleButton
                    key={num}
                    className="toggleButton"
                    value={num.toString()}
                    aria-label={`${num} players`}>
                    {`${num} players`}
                </ToggleButton>
            ))}
		</ToggleButtonGroup>
	);
}
