import React, { useState, useEffect } from "react";
import axios from "axios";
import {
	Container,
	List,
	ListItem,
	Button,
	Typography,
	TableContainer,
	Table,
	Switch,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Loader } from "../components/loader.comp";
import { motion } from "framer-motion";

export function PlayerPage(props) {
	const [players, setPlayers] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/api/players/")
			.then((response) => {
				setPlayers(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<Container
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}
			sx={{ mt: 2 }}>
			<Loader loading={!players} />
			<Typography variant="h4">Players:</Typography>

			<TableContainer>
				<Table>
					<TableHead>
						<TableRow style={{ marginTop: "1em", paddingBottom: "16px" }}>
							<TableCell style={{ paddingLeft: "1em", fontSize: "1.5em", width: "60%" }}>Name</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{players?.map((player) => {
							return (
								<TableRow key={player._id}>
									<TableCell
										size="small"
										style={{ width: "50%" }}>
										<Typography
											sx={{ flexDirection: "row", display: "flex" }}
											variant="h6">
											{player.playerName}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Button
				sx={{ mt: 2 }}
				variant="contained"
				fullWidth
				onClick={() => navigate("/players/new")}>
				Add Player
			</Button>
		</Container>
	);
}
