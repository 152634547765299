import { Backdrop, Paper, Typography, Button, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useWebSocket } from "../WebSocketContext";
import { useLocation } from "react-router-dom";

export default function DisconnectComponent() {
	const [showBlock, setShowBlock] = useState(false);
	const [webSocketPath, setWebSocketPath] = useState(
		["game", "start"].some((subPath) => location.pathname.includes(subPath))
	);
	const { monitorConnection, isConnectionOpen } = useWebSocket();
	const [connectionStatus, setConnectionStatus] = useState(false);
	const [timeLeft, setTimeLeft] = useState(100);
	const rrdLocation = useLocation();
	const [reloads, setReloads] = useState(parseInt(localStorage.getItem("magicounter-reloads")) || 0);

	useEffect(() => {
		if (rrdLocation) {
			setWebSocketPath(["game", "start"].some((subPath) => location.pathname.includes(subPath)));
		}
	}, [rrdLocation]);

	useEffect(() => {
		let interval;

		if (showBlock && timeLeft > 0) {
			interval = setInterval(() => {
				setTimeLeft((prevTime) => prevTime - 1);
			}, 10);
		}

		return () => {
			clearInterval(interval);
		};
	}, [showBlock, timeLeft]);

	useEffect(() => {
		if (!showBlock) {
			setTimeLeft(100);
		}
	}, [showBlock]);

	useEffect(() => {
		// Monitor the WebSocket connection
		const onConnect = () => {
			setConnectionStatus(true);
		};
		const onDisconnect = () => {
			setConnectionStatus(false);
		};
		monitorConnection(onConnect, onDisconnect);
	}, [monitorConnection]);

	const onFocus = () => {
		if (!isConnectionOpen()) {
			setConnectionStatus(false);
		}
	};

	useEffect(() => {
		window.addEventListener("focus", onFocus);
		// Calls onFocus when the window first loads
		onFocus();
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener("focus", onFocus);
		};
	}, []);

	function handleClick() {
		localStorage.setItem("magicounter-reloads", "0");
		setReloads(0);

		window.location.reload();
	}

	function handleAutoReload() {
		let reloads = parseInt(localStorage.getItem("magicounter-reloads"));
		if (reloads === null || reloads === undefined) {
			reloads = 0;
		}
		reloads++;
		setReloads(reloads);
		localStorage.setItem("magicounter-reloads", reloads.toString());
		if (reloads < 5) {
			window.location.reload();
		}
	}
	useEffect(() => {
		console.log("reloads", reloads);
	}, [reloads]);

	useEffect(() => {
		if (timeLeft <= 0) {
			handleAutoReload();
		}
	}, [timeLeft]);

	useEffect(() => {
		if (!webSocketPath) {
			setShowBlock(false);
		} else if (connectionStatus === false) {
			setShowBlock(true);
		} else {
			setShowBlock(false);
		}
	}, [webSocketPath, connectionStatus]);

	return (
		<Backdrop
			sx={{ padding: 2, color: "#fff", zIndex: 99999999 }}
			open={showBlock}>
			<Paper sx={{ padding: 2 }}>
				<Stack spacing={2}>
					<Typography
						textAlign={"center"}
						variant="h3">
						Disconnected
					</Typography>
					{reloads < 5 && <Typography variant="h5">Please wait while we try to reconnect you</Typography>}
					{reloads < 5 && <Typography variant="h5">Attempt {reloads + 1} out of 5</Typography>}
					{reloads >= 5 && (
						<Typography variant="h5">Could not reconnect, please try a manual refresh</Typography>
					)}

					<Button
						sx={{
							backgroundColor: timeLeft !== 0 ? "rgba(255, 255, 255, 0.05)" : "",
							background:
								timeLeft !== 0
									? `linear-gradient(90deg, rgba(255, 255, 255, 0.05) ${timeLeft}%, rgba(255, 255, 255, 0.0) ${timeLeft}%)`
									: "",
						}}
						disabled={timeLeft > 0}
						variant="contained"
						onClick={handleClick}>
						{timeLeft > 0 ? `Reconnecting...(${parseInt(timeLeft / 20) + 1})` : "Refresh"}
					</Button>
				</Stack>
			</Paper>
		</Backdrop>
	);
}
