import React from "react";
import "./styling/app.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AnimatePresence } from "framer-motion";

import GamePage from "./pages/Game.page";
import { DeckPage } from "./pages/Decks.page";
import { EditDeckPage } from "./pages/EditDeck.page";
import { PlayerPage } from "./pages/Players.page";
import PlayerCreatePage from "./pages/CreatePlayer.page";
import { NavigationBar } from "./components/navi.comp";
import MatchPage from "./pages/StatsMatch.page";
import { StatsPage } from "./pages/StatsOverview.page";
import { LoginPage } from "./pages/Login.page";
import Disconnected from "./components/disconnected.comp";
import { StartPage } from "./pages/Start.page";
import { WebSocketProvider } from "./WebSocketContext";

function App() {
	return (
		<BrowserRouter>
			<WebSocketProvider>
				<ThemeProvider theme={darkTheme}>
					<AnimatePresence>
						<CssBaseline />
						<Disconnected />
						<NavigationBar />
						<Routes>
							<Route
								path="/"
								element={<StartPage />}
							/>
							<Route
								path="/start"
								element={<StartPage />}
							/>
							<Route
								path="/game"
								element={<GamePage />}
							/>
							<Route
								path="/decks"
								element={<DeckPage />}
							/>
							<Route
								path="/decks/new"
								element={<EditDeckPage />}
							/>
							<Route
								path="/decks/edit/:deckIdParam"
								element={<EditDeckPage />}
							/>
							<Route
								path="/players"
								element={<PlayerPage />}
							/>
							<Route
								path="/players/new"
								element={<PlayerCreatePage />}
							/>
							<Route
								path="/stats"
								element={<StatsPage />}
							/>
							<Route
								path="/match/:matchIdParam"
								element={<MatchPage />}
							/>
							<Route
								path="/login"
								element={<LoginPage />}
							/>
						</Routes>
					</AnimatePresence>
				</ThemeProvider>
			</WebSocketProvider>
		</BrowserRouter>
	);
}
const darkTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#78c5ef",
		},
	},
});
export default App;
