import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Number from "../components/number.comp.js";
import { TableContainer } from "@mui/material";
// import { OnlyNumber } from "../components/onlyNumber.comp.js";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	if (array) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) return order;
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
}

const headCells = [
	{ id: "name", numeric: false, disablePadding: false, label: "Name" },
	{ id: "wins", numeric: true, disablePadding: false, label: "Wins" },
	{ id: "games", numeric: true, disablePadding: false, label: "Games" },
	{ id: "winrate", numeric: true, disablePadding: false, label: "Winrate" },
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead>
			<TableRow sx={{ display: "flex", width: "100%" }}>
				{headCells.map((headCell) => (
					<TableCell
						sx={{ pl: 1, pr: 0, width: "25%" }}
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable(props) {
	const [order, setOrder] = React.useState("desc");
	const [orderBy, setOrderBy] = React.useState("winrate");
	const [selected, setSelected] = React.useState([]);
	const rows = props.inputData;

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	if (rows) {
		return (
			<TableContainer sx={{ maxWidth: "100%" }}>
				<Table sx={{ maxWidth: "100%", width: "100%", borderCollapse: "collapse" }}>
					<EnhancedTableHead
						sx={{ maxWidth: "100%" }}
						numSelected={selected.length}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						rowCount={rows ? rows.length : 0}
					/>
					<TableBody sx={{ maxWidth: "100%", width: "100%" }}>
						{stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
							const labelId = `enhanced-table-checkbox-${index}`;
							let winRatePercent = row.winrate;
							return (
								<TableRow
									onClick={(event) => handleClick(event, row.name)}
									tabIndex={-1}
									key={row.name}
									style={{
										display: "flex",
										width: "100%",
										backgroundColor: "rgba(255, 255, 255, 0.05)",
										background: `linear-gradient(90deg, rgba(255, 255, 255, 0.05) ${winRatePercent}%, rgba(255, 255, 255, 0.0) ${winRatePercent}%)`,
									}}>
									<TableCell
										sx={{ width: "25%" }}
										component="th"
										id={labelId}
										scope="row">
										{row.name}
									</TableCell>
									<TableCell
										sx={{ width: "25%" }}
										align="right">
										<Number value={row.wins} />
									</TableCell>
									<TableCell
										sx={{ width: "25%" }}
										align="right">
										<Number value={row.games} />
									</TableCell>
									<TableCell
										sx={{ width: "25%" }}
										align="right">
										<Number value={row.winrate} />
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	} else {
		return (
			<Table
				sx={{ maxWidth: "100%" }}
				aria-labelledby="tableTitle"
				aria-label="enhanced table">
				<EnhancedTableHead
					numSelected={selected.length}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					rowCount={rows ? rows.length : 0}
				/>
			</Table>
		);
	}
}
