import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {
	Container,
	FormGroup,
	FormControl,
	FormLabel,
	InputLabel,
	Select,
	MenuItem,
	Button,
	Stack,
} from "@mui/material";
import colorBlock from "../styling/colorBlock";
import { useParams } from "react-router";
import { ColorSelection } from "./../components/ColorSelection.comp";
import DeckTypeSelector from "../components/DeckTypeSelector.comp";
import { Loader } from "../components/loader.comp";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function EditDeckPage(props) {
	const [deckId, setDeckId] = useState();
	const [deckName, setDeckName] = useState();
	const [deckNickName, setDeckNickName] = useState();
	const [deckActive, setDeckActive] = useState();
	const [deckColors, setDeckColors] = useState([]);
	const [deckType, setDeckType] = useState();
	const [deckSubType, setDeckSubType] = useState();
	const [playerOptions, setPlayerOptions] = useState();
	const [deckPlayer, setDeckPlayer] = useState();
	const [loading, setLoading] = useState(true);

	const { deckIdParam } = useParams();
	const navigate = useNavigate();

	function resetStates() {
		setDeckId();
		setDeckName();
		setDeckNickName();
		setDeckActive();
		setDeckColors([]);
		setDeckType();
		setDeckSubType();
		setPlayerOptions();
		setDeckPlayer();
		setLoading(false);
	}

	const getPlayerOptions = () => {
		axios.get("/api/players/").then((response) => {
			setPlayerOptions(response.data);
		});
	};

	const getDeckInfo = () => {
		axios.get(`/api/decks/${deckId}`).then((response) => {
			setDeckName(response.data.deckName);
			setDeckNickName(response.data.deckNickName);
			setDeckActive(response.data.deckActive);
			setDeckColors(response.data.deckColors);
			setDeckType(response.data.deckType);
			setDeckSubType(response.data.deckSubType);
			setDeckPlayer(response?.data?.player?._id || undefined);
		});
	};

	const selectDeckType = (event) => {
		const [newDeckType, newDeckSubType] = event.target.value.split("-");
		setDeckType(newDeckType);
		setDeckSubType(newDeckSubType);
	};

	const submitChanges = (event) => {
		event.preventDefault();
		setLoading(true);
		const reqBody = {
			deckName,
			deckNickName,
			deckColors,
			deckActive,
			deckType,
			deckSubType,
			player: deckPlayer,
		};
		const postUrl = deckId ? `/api/decks/update/${deckId}` : "/api/decks/add";
		axios
			.post(postUrl, reqBody)
			.then(() => {
				navigate("/decks");
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "deckPlayer") {
			setDeckPlayer(value);
		} else {
			switch (name) {
				case "deckName":
					setDeckName(value);
					break;
				case "deckNickName":
					setDeckNickName(value);
					break;
				default:
					break;
			}
		}
	};

	const toggleColor = (event) => {
		const colorName = event.target.name;
		const isChecked = event.target.checked;
		if (isChecked) {
			setDeckColors([...deckColors, colorName]);
		} else {
			setDeckColors(deckColors.filter((color) => color !== colorName));
		}
	};

	useEffect(() => {
		getPlayerOptions();
	}, []);

	useEffect(() => {
		if (deckId) {
			getDeckInfo();
		}
	}, [deckId]);

	useEffect(() => {
		if (deckIdParam) {
			setDeckId(deckIdParam);
		} else {
			resetStates();
		}
	}, [deckIdParam]);

	useEffect(() => {
		if (deckName && playerOptions.length > 0) {
			setLoading(false);
		}
	}, [deckName, playerOptions]);

	return (
		<Container
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}
			style={{ paddingBottom: "2em" }}>
			<Loader loading={loading} />
			<form onSubmit={submitChanges}>
				<FormGroup
					role="form"
					autoComplete="off">
					<TextField
						sx={{ mt: 4 }}
						name="deckName"
						onChange={handleChange}
						label="Deck Name"
						variant="outlined"
						value={deckName || ""}
					/>

					<TextField
						sx={{ mt: 2 }}
						name="deckNickName"
						onChange={handleChange}
						label="Deck Nickname"
						variant="outlined"
						value={deckNickName || ""}
					/>

					<FormControl
						sx={{ mt: 2 }}
						component="fieldset">
						<FormLabel component="legend">Deck Colors</FormLabel>
						<ColorSelection
							colors={deckColors}
							toggleColor={toggleColor}
						/>
					</FormControl>
					<Stack
						style={colorBlock(deckColors, {
							width: "100%",
							height: "25px",
							borderRadius: "5px",
							border: "solid black 1px",
						})}></Stack>

					<FormControl sx={{ mt: 2 }}>
						<DeckTypeSelector
							deckType={deckType}
							deckSubType={deckSubType}
							selectDeckType={selectDeckType}
						/>
					</FormControl>

					<FormControl sx={{ mt: 2 }}>
						<InputLabel>Deck owner</InputLabel>
						<Select
							label="Deck owner"
							value={deckPlayer || ""}
							onChange={handleChange}
							name="deckPlayer">
							{playerOptions?.length > 0 ? (
								playerOptions?.map((player) => (
									<MenuItem
										key={player._id}
										value={player._id}>
										{player.playerName}
									</MenuItem>
								))
							) : (
								<MenuItem value="">No Players</MenuItem>
							)}
						</Select>
					</FormControl>

					<Button
						sx={{ mt: 4 }}
						variant="contained"
						type="submit"
						color="primary">
						Save Changes
					</Button>
				</FormGroup>
			</form>
		</Container>
	);
}
